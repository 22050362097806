import React, { useEffect, useState } from 'react';

function SubjectSelector({ selectedClass, onSelectSubject }) {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetch('/data/subject_list.json')
      .then(response => response.json())
      .then(data => {
        const filteredSubjects = data.filter(
          subject => subject.className === selectedClass && subject.isPublished
        );
        setSubjects(filteredSubjects);
      })
      .catch(error => console.error('Error fetching subjects data:', error));
  }, [selectedClass]);

  return (
    <div>
      <h2>Select a Subject of {selectedClass} </h2>
      {subjects.map(subject => (
        <button key={subject._id} onClick={() => onSelectSubject(subject._id)}>
          {subject.subjectBn} ({subject.subjectEn})
        </button>
      ))}
    </div>
  );
}

export default SubjectSelector;

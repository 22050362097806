import React, { useEffect, useState } from 'react';

function ClassSelector({ onSelectClass }) {
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    fetch('/data/subject_list.json')
      .then(response => response.json())
      .then(data => {
        // Extract unique class names from the data
        const uniqueClasses = [...new Set(data.map(item => item.className))];
        setClasses(uniqueClasses);
      })
      .catch(error => console.error('Error fetching class data:', error));
  }, []);

  return (
    <div>
      <h2>Select a Class</h2>
      {classes.map(cls => (
        <button key={cls} onClick={() => onSelectClass(cls)}>
          {cls}
        </button>
      ))}
    </div>
  );
}

export default ClassSelector;

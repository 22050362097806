import React from 'react';

function QuizResult({ score, total, onRestart }) {
  return (
    <div>
      <h2>Quiz Result</h2>
      <p>
        You scored {score} out of {total}
      </p>
      <button onClick={onRestart}>Restart Quiz</button>
    </div>
  );
}

export default QuizResult;
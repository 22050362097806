import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <p>@Astick Banejee © 2024 Banglar Bidyalay. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './app';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <main style={{ flex: 1, marginTop: '5', marginBottom: '5' }}>
        <App />
      </main>
      <Footer />
    </div>
  </React.StrictMode>
);
import React, { useState } from 'react';
import './QuizQuestion.css';

function QuizQuestion({
  selectedClass,
  subject,
  question,
  onAnswer,
  onNextQuestion,
  onQuitQuiz,
  score,
  currentQuestionNumber,
  totalQuestions,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const correctSound = new Audio('/sounds/correct.mp3');
  const incorrectSound = new Audio('/sounds/incorrect.mp3');

  correctSound.muted = isMuted;
  incorrectSound.muted = isMuted;

  const handleAnswerClick = (option) => {
    if (!answerSubmitted) {
      setSelectedAnswer(option);
      setAnswerSubmitted(true);
      if (option === question.correct_answer) {
        correctSound.play();
      } else {
        incorrectSound.play();
      }
      onAnswer(option);
    }
  };

  const getOptionClass = (option) => {
    if (!answerSubmitted) return 'option';
    if (option === question.correct_answer) return 'option correct';
    if (option === selectedAnswer && option !== question.correct_answer)
      return 'option incorrect';
    return 'option';
  };

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <div className="subject">{selectedClass}-{subject}</div>
        <div className="score">Score: {score}/{totalQuestions}</div>
        {/* <div className="question-number">{currentQuestionNumber}</div> */}
        <button
          className="mute-button"
          onClick={() => setIsMuted(!isMuted)}
        >
          {isMuted ? '🔇' : '🔊'}
        </button>
      </div>
      <div className="question">
        <h2>Q{currentQuestionNumber}. {question.question}</h2>
      </div>
      <div className="options">
        {question.options.map((option, index) => (
          <button
            key={index}
            className={getOptionClass(option)}
            onClick={() => handleAnswerClick(option)}
            disabled={answerSubmitted}
          >
            {String.fromCharCode(65 + index)}. {option}
          </button>
        ))}
      </div>
      <div className="quiz-footer">
        <button
          className="next-question"
          onClick={() => {
            onNextQuestion();
            setSelectedAnswer(null);
            setAnswerSubmitted(false);
          }}
          disabled={!answerSubmitted}
        >
          Next Question
        </button>
        <button className="quit-quiz" onClick={onQuitQuiz}>Quit Quiz</button>
      </div>
    </div>
  );
}

export default QuizQuestion;

import React, { useState, useEffect } from 'react';
import ClassSelector from './components/ClassSelector';
import SubjectSelector from './components/SubjectSelector';
import QuizQuestion from './components/QuizQuestion';
import QuizResult from './components/QuizResult';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import './App.css';

function App() {
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const baseUrl = 'https://api.banglarbidyalay.com';

  const CustomListItemButton = styled(ListItemButton)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    borderRadius: '8px',
    margin: '8px 0',
    boxShadow: theme.shadows[1],
  }));

  const fetchChapters = async (subjectId) => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/chapters-by-subject-id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify({ subjectId })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chapters from db');
      }

      const data = await response.json();
      setChapters(data);
      if (data.length === 0) {
        setOpenDialog(true); // Open dialog if no chapters
      }
    } catch (error) {
      console.error('Error fetching chapters:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClass && selectedSubject) {
      fetchChapters(selectedSubject._id);  // Use subject ID
    }
  }, [selectedClass, selectedSubject]);

  useEffect(() => {
    if (selectedSubject && selectedChapter) {
      setLoading(true);
      fetch(`/data/${selectedSubject._id}.json`)  // Fetch JSON by subject ID
        .then(response => response.json())
        .then(data => {
          const questions = data[selectedChapter];  // Get chapter data
          if (questions && questions.length) {
            setQuizData(questions);
            setCurrentQuestion(0);
            setScore(0);
            setShowResult(false);
          } else {
            alert('No questions found for the selected chapter.');
          }
        })
        .catch(error => console.error('Error fetching quiz data:', error))
        .finally(() => setLoading(false));
    }
  }, [selectedSubject, selectedChapter]);

  const handleAnswer = (selectedAnswer) => {
    const currentQuestionData = quizData[currentQuestion];
    if (selectedAnswer === currentQuestionData.correct_answer) {
      setScore(score + 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion + 1 < quizData.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
  };

  const handleQuitQuiz = () => {
    setSelectedChapter(null);
    setQuizData(null);
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
  };

  const handleBack = () => {
    if (selectedChapter) {
      setSelectedChapter(null);
    } else if (selectedSubject) {
      setSelectedSubject(null);
      setChapters([]);
    } else if (selectedClass) {
      setSelectedClass(null);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="App">
      <h1>Play Quiz</h1>
      {loading && <CircularProgress />}
      {!loading && !selectedClass && (
        <ClassSelector onSelectClass={setSelectedClass} />
      )}
      {selectedClass && !selectedSubject && (
        <>
          <Button onClick={handleBack}>Back</Button>
          <SubjectSelector selectedClass={selectedClass} onSelectSubject={setSelectedSubject} />
        </>
      )}
      {selectedSubject && !selectedChapter && (
        <>
          <Button onClick={handleBack} variant="contained" color="primary" style={{ marginBottom: '16px' }}>Back</Button>
          <List>
            {chapters
              .sort((a, b) => a.position - b.position)
              .map((chapter) => (
                <ListItem key={chapter._id} disablePadding>
                  <CustomListItemButton onClick={() => setSelectedChapter(chapter._id)}>
                    <ListItemText primary={chapter.chapterTitleBn} />
                  </CustomListItemButton>
                </ListItem>
              ))}
          </List>
        </>
      )}
      {quizData && !showResult && (
        <QuizQuestion
          selectedClass={selectedClass}
          subject={selectedSubject}
          question={quizData[currentQuestion]}
          onAnswer={handleAnswer}
          onNextQuestion={handleNextQuestion}
          onQuitQuiz={handleQuitQuiz}
          score={score}
          currentQuestionNumber={currentQuestion + 1}
          totalQuestions={quizData.length}
        />
      )}
      {showResult && (
        <QuizResult
          score={score}
          total={quizData.length}
          onRestart={restartQuiz}
        />
      )}

      {/* Dialog for no chapters */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>No Chapters Available</DialogTitle>
        <DialogContent>
          <p>No chapters exist for this subject.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
